<template>

  <div id="main-wrapper">
    <vcl-facebook :rtl="true" v-if="!pageloading" ></vcl-facebook>
    <vcl-instagram :rtl="true" v-if="!pageloading"></vcl-instagram>
    <vcl-facebook :rtl="true" v-if="!pageloading" ></vcl-facebook>

    <div v-else class="main-content is-footer">
      <!--پرفروشترین ها--->
      <v-container >

        <v-row style="width: 100%;background: #eee;border-radius: 15px;margin: auto">
          <v-col >
            <p class="text-center font-medium font-weight-bold pt-3 loading-item" style="height: 100%"><v-icon color="black" size="27">mdi-seal</v-icon> قسمت انتقالات مالی شما </p>
          </v-col>
        </v-row>
        <v-divider style="margin-top:15px;"></v-divider>
        <v-row style="width: 100%;background: #b2b48a;border-radius: 15px;margin: auto" v-if="mjayeze>0 || mdaraee>0 || mporsant>0">
          <v-col >
            <p style="font-weight: bold;font-size: small" v-if="mjayeze!=0"> موجودی صندوق جایزه : {{helperNumberFormat(mjayeze)}} تومان</p>
            <p style="font-weight: bold;font-size: small" v-if="mdaraee!=0">موجودی صندوق دارایی : {{helperNumberFormat(mdaraee)}} تومان</p>
            <p style="font-weight: bold;font-size: small" v-if="mporsant!=0">موجودی صندوق پورسانت : {{helperNumberFormat(mporsant)}} تومان</p>
          </v-col>
        </v-row>

        <v-col cols="12">
          <div  class="text-center" >
            <p style="font-weight: bold;">لطفا فرم زیر را تکمیل کنید</p>

            <v-text-field v-model="mablagh" outlined label=" مبلغ (به تومان)"  ></v-text-field>

            <v-row>
              <v-col cols="12">
                <div class="text-sm text-muted mb-1"> از چه قسمتی میخواهید برداشت نمایید:</div>
                <v-autocomplete
                    v-model="noetabadolid"
                    :items="noetabadol"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label=" نوع تبادل "
                    rounded

                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-text-field v-if="noetabadolid==5" v-model="codeusermaghsad" outlined label="کد کاربر مقصد"  ></v-text-field>


        <v-col>
        <v-row>
        </v-row>
        </v-col>
            <v-btn rounded @click="enteghal(noetabadolid)" :loading="loading" depressed color="primary" height="50" style="width: 100%"> انجام تبادل </v-btn>
          </div>
        </v-col>



      </v-container>


    </div>
    <app-footer></app-footer>
   </div>
</template>


<script>


import VuePictureSwipe from 'vue-picture-swipe';
import {mapGetters} from 'vuex'
import appFooter from '../views/_footer'
import axios from "axios";
import { VclFacebook, VclInstagram } from 'vue-content-loading';
import Uploader from "vux-uploader-component";


export default {
  name: 'probleminsert',
  components: {
    VuePictureSwipe,
    VclFacebook,
    VclInstagram,
    Uploader,
    appFooter,
  },
  data () {
    return {
      show: false,
      showcam:false,
      url:'https://sharjtosharj.com/soccerp/',
      varcat:{},
      loading:false,
      pageloading:false,
      mablagh:"",
      noetabadol:[
        {id:'1',name:'برداشت از صندوق جایزه به بانک'},
        {id:'2',name:'برداشت از صندوق جایزه به کاربری'},
        {id:'3',name:'برداشت از صندوق پورسانت به بانک'},
        {id:'4',name:'برداشت از صندوق پورسانت به کاربری'},
       // {id:'5',name:'برداشت از صندوق کاربری به کاربر دیگر'},
      ],
      noetabadolid:1,
      codeusermaghsad:"",
      mjayeze:0,
      mdaraee:0,
      mporsant:0,
    }
  },
  mounted() {
    let self = this;
    self.pageloading=true;
    self.getvar();
  },
  computed:{
    ...mapGetters(['baseUrl','baseUrlproblem',"auth"]),
  },
  methods:{
    randomColor() {
      const r = () => Math.floor(150 * Math.random());

      return  `rgb(${r()}, ${r()}, ${r()})`;
    },

    getvar(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);

      axios.post(this.baseUrl+'/mojodi.aspx', formData, config)
          .then(function (r) {
            //console.log(r.data);
            if(r.data.data.result=="1"){
                 self.mdaraee=r.data.data.daraee;
                 self.mjayeze=r.data.data.jayeze;
                 self.mporsant=r.data.data.porsant;
            }
            else
            {
              self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'red'
            });
              if (self.form.length==0)
                self.$router.push({path:'/admin'});

            }
            self.loading = false;
          });




    },
    enteghal(id) {

      if (id == 1) {
        if (this.mablagh >= 50000) {
          this.enteghalbebank();
        } else {
          this.helperToast({
            show: true,
            text: "حداقل مبلغ قابل انتقال پنجاه هزار تومان است",
            color: 'red'
          });
        }
      } else if (id == 2)
      {
          this.enteghalbekarbari();
      }
      else if (id == 3)
      {
        if (this.mablagh >= 50000) {
        this.enteghalporbebank();
        } else {
          this.helperToast({
            show: true,
            text: "حداقل مبلغ قابل انتقال پنجاه هزار تومان است",
            color: 'red'
          });
        }
      }
      else if (id == 4)
      {
        this.enteghalporbedaraee();
      }
      else if (id == 5)
      {
        if (this.mablagh >= 10000 && this.mablagh <= 1000000) {
          if (this.codeusermaghsad > 0) {
            this.enteghalvajh();
          }
          else {
            this.helperToast({
              show: true,
              text: "کد کاربر مقصد را درست وارد کنید",
              color: 'red'
            });
          }
        } else {
          this.helperToast({
            show: true,
            text: "حداقل مبلغ قابل انتقال 10 هزار تومان و حداکثر 1 میلیون تومان است",
            color: 'red'
          });
        }
      }



    },
    enteghalbebank(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("pool", self.mablagh);

      axios.post(this.baseUrl+'/varizpool.aspx', formData, config)
          .then(function (r) {
              self.helperToast({
                show:true,
                text:r.data.data.message,
                color:'green'
              });

            self.loading = false;
            self.$router.push({path:'/admin'});
          });




    },
    enteghalbekarbari(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("pool", self.mablagh);

      axios.post(this.baseUrl+'/varizdaraee.aspx', formData, config)
          .then(function (r) {
            self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'green'
            });
            self.loading = false;
            self.$router.push({path:'/admin'});

          });




    },
    enteghalporbebank(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("pool", self.mablagh);

      axios.post(this.baseUrl+'/varizporsant.aspx', formData, config)
          .then(function (r) {
            self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'green'
            });
            self.loading = false;
            self.$router.push({path:'/admin'});

          });




    },
    enteghalporbedaraee(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("pool", self.mablagh);

      axios.post(this.baseUrl+'/varizporsantbedaraee.aspx', formData, config)
          .then(function (r) {
            self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'green'
            });
            self.loading = false;
            self.$router.push({path:'/admin'});

          });




    },
    enteghalvajh(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();

      const num = this.codeusermaghsad.replace(" ", "");
      const p2e = num => num.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

      formData.append("token", self.appToken);
      formData.append("code_shakhs", p2e);
      formData.append("Code_enteghal", self.$store.getters.auth.data.user_id);
      formData.append("pool", self.mablagh);

      axios.post(this.baseUrl+'/enteghalvajh.aspx', formData, config)
          .then(function (r) {
            self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'green'
            });
            self.loading = false;
            self.$router.push({path:'/admin'});

          });




    },
  },
  watch:{

  }
}
</script>

<style>
.border-grid .col{
  height: 100px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid .col:nth-child(3n + 0){
  border-left: none;
}
.border-grid .col:nth-last-child(-n + 3){
  border-bottom: none;
}
.border-grid .col p{
  font-weight: 600;
}

.border-grid2 .col{
  height: 300px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid2 .col:nth-child(2n + 0){
  border-left: none;
}
.border-grid2 .col:nth-last-child(-n + 2){
  border-bottom: none;
}
.border-grid2 .col p{
  font-weight: 600;
}

.camera-box {
  border: 1px dashed #d6d6d6;
  border-radius: 4px;
  padding: 2px;
  width: 100%;
  min-height: 500px;
}

loading-item {
  min-width: 100px !important;
  margin-bottom: 0;
}

</style>
